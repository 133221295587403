<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-13 15:39:33
 * @LastEditTime: 2025-02-13 09:38:15
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \mfzj\src\views\layout\work\okr\alignTheView\index.vue
-->
<template>
  <section>
    <div class="my-card myokr-top">
      <div class="maxwidth">
        <p class="goto" @click="back"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"></path></svg>
        返回
        </p>
        <div class="myokr-top-right">
          <pickDate ref="okrCalendar" @confirmSelDate="confirmSelDate" :btnStatus="false"></pickDate>
          <span class="active-text-color cursor-pointer" @click="tabAlignment">{{targetAlignedText}}</span>
        </div>
      </div>
    </div>
    <router-view :dateList="dateList"/>
  </section>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import eventBus from '@/utils/eventBus.js'
export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    // 判断状态
    const justTargetNotAligned = () => {
      return route.name === 'targetAligned'
    }
    const store = useStore()

    const state = reactive({
      targetAlignedText: justTargetNotAligned() ? '查看未对齐目标' : '查看对齐目标 ',
      okrCalendar: null,
      dateList: [],
      backData: computed(() => store.state.okr.menu)
    })

    const getClickDateList = (dateList) => {
      const clickDateList = []
      if (Array.isArray(dateList)) {
        dateList.forEach(element => {
          clickDateList.push(element.id)
        })
      } else {
        clickDateList.push(dateList)
      }
      state.dateList = clickDateList
      // console.log(dateList)
      // console.log(state.dateList)
    }

    // 选择日期
    const confirmSelDate = ({ isYearCycle, selID, selYear }) => {
      let data = []
      if (!isYearCycle) {
        data = selID
      } else {
        data = [{ id: selID, name: selYear }]
      }
      getClickDateList(data)
      eventBus.$emit('setScrollTop')
    }

    const tabAlignment = () => {
      state.targetAlignedText = justTargetNotAligned() ? '查看对齐目标' : '查看未对齐目标'
      router.push({ name: justTargetNotAligned() ? 'targetNotAligned' : 'targetAligned' })
    }

    const back = () => {
      let firstDep
      if (route.query.originDepId) {
        firstDep = state.backData.filter(i => i.name === '我的OKR')[0].children.filter(i => Number(i.dept_id) === Number(route.query.originDepId))[0]
      } else {
        firstDep = state.backData.filter(i => i.name === '我的OKR')[0].children[0]
      }
      router.push({ name: 'myokr', params: { id: firstDep.dept_id, depName: firstDep.name, queryDate: route.query.date ? JSON.stringify(route.query.date) : undefined } })
    }

    return {
      ...toRefs(state),
      confirmSelDate,
      tabAlignment,
      back
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.target-dialog) {
  border-radius: 6px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;

    .el-dialog__header {
      display: block;
    }
  }
}
.myokr-top {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  height: 46px;
  line-height: 18px;
  font-weight: 400;
  font-size: 14px;
  color: @default-text-color;
  flex-direction: column;
  transform: translateY(-10px);

  .maxwidth {
    display: flex;
    margin: auto;
    padding: 0 24px 0 24px;
    max-width: 1120px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    .goto {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        width: 15px;
        transform: rotate(90deg);
      }

      &:hover {
        color: @active-text-color;
      }
    }

    .myokr-top-right {
      display: flex;
      transform: translateY(6px);

      :deep(.pick-wrapper) {
        position: relative;

        .selector {
          left: -20px;
          top: 35px;
        }
      }
    }
    .myokr-top-right > span{
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      margin-left: 16px;
    }
  }
}
:deep(.org-chart-container) {
  display: flex;
  align-items: center;
  min-height: calc(50vh - 220px);
  margin: 0 30px;
  padding: 80px 0 50px;
  transform: translateX(-40px);

  .org-chart-node-label {
    width: 220px;
  }

  .org-chart-node:not(.is-left-child-node):first-child:after {
    border-radius: 55px 0 0 0;
  }

  .org-chart-node:not(.is-left-child-node):not(.is-not-child):last-child:before {
    border-radius: 0 0 0 55px;
  }

  .org-chart-node-label-inner{
    margin: 7.5px 0;
    min-width: 220px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .horizontal .org-chart-node-label .org-chart-node-btn:after,
  .horizontal .org-chart-node-label .org-chart-node-left-btn:after,
  .horizontal .org-chart-node-label .org-chart-node-btn:before,
  .horizontal .org-chart-node-label .org-chart-node-left-btn:before {
    border-color:  @active-text-color;
    border-width: 2px;
  }

  .horizontal .org-chart-node-label .org-chart-node-btn {
    margin-left: 30px;
    margin-top: -12px;
    overflow: hidden;
  }

  .horizontal .org-chart-node-label .org-chart-node-btn .num {
    position: relative;
    z-index: 2;
    line-height: 24px;
    background: #fff;
  }

  .horizontal .org-chart-node:not(.is-left-child-node):after,
  .horizontal .org-chart-node:not(.is-left-child-node):before,
  .horizontal .org-chart-node-children:before,
  .horizontal .org-chart-node:not(.is-left-child-node):after {
    width: 40px;
  }

  .horizontal .org-chart-node:not(.is-left-child-node),
  .horizontal .org-chart-node-children,
  .horizontal .org-chart-node-left-children {
    padding-left: 40px;
  }

  .horizontal .is-left-child-node.collapsed .org-chart-node-label:before,
  .horizontal .org-chart-node.collapsed .org-chart-node-label:after {
    width: 30px;
  }

}
:deep(.horizontal .org-chart-node-label .org-chart-node-btn),
:deep(.org-chart-node-btn-text) {
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  border: none;
  color:  @active-text-color;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

:deep(.three-card) {

  &.no-alignment {
    padding: 10px;
    width: 220px;
    border: 1px solid #F1F1F1;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    background: #FFFFFF;
    cursor: pointer;
    &:hover {
      box-shadow: 0 1px 14px rgba(31,35,41, 0.12);
    }
  }

  .three-card-head {
    display: flex;
    justify-content: space-between;

    .tag {
      display: block;
      padding: 0 4px;
      height: 16px;
      color: @active-text-color;
      font-size: 12px;
      line-height: 16px;
      border-radius: 18px;
      text-align: center;
      background: rgba(121, 114, 240, 0.2);
    }
    .el-progress {
      margin-right: 30px;
      .el-progress__text {
        margin-left: 8px;
        color: @active-text-color;
        font-size: 12px !important;
        font-weight: bold;
      }
    }
  }
  .three-card-content {
    margin-top: 10px;
    h3 {
      color: #333;
      min-height: 38px;
      font-size: 14px;
      text-align: left;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 20px;
    }
    .tag {
      display: flex;
      margin-top: 10px;
      text-align: left;
      color: #666;
      font-size: 12px;
      justify-content: space-between;

      .dept {
        display: flex;
      }
      .cycle {
        min-width: 70px;
        text-align: right;
      }

      svg {
        margin-right: 6px;
        width: 16px;
        min-width: 16px;
      }
    }
  }
}

.department-list {
  display: flex;
  color: #333;
  padding-top: 100px;
  .department+.department {
    margin-left: 80px;
  }
  .three-card {
    margin-bottom: 15px;
  }
}

</style>
